import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SEGMENTS_STORE_KEY, SegmentsState } from './segments.state';

// tslint:disable-next-line:no-namespace
export namespace SegmentsSelectors {
  const selectSegmentsFeature = createFeatureSelector<SegmentsState>(SEGMENTS_STORE_KEY);

  export const selectSegments = createSelector(selectSegmentsFeature, (state) => state.segments);

  export const selectSelectedSegment = createSelector(selectSegmentsFeature, (state) => state.selectedSegment);

  export const selectSegmentContacts = createSelector(selectSegmentsFeature, (state) => state.segmentContacts);
}
