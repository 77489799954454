import { FormArray, FormControl, FormGroup } from '@angular/forms';

export function updateValueAndValidity(form: FormGroup | FormArray | FormControl): void {
  if (form) {
    if (form instanceof FormControl) {
      updateControl(form);
    } else {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field) as FormGroup | FormArray | FormControl;
        updateValueAndValidity(control);
      });
      form.updateValueAndValidity();
    }
  }
}

function updateControl(control: FormControl): void {
  control.markAsTouched({ onlySelf: true });
  control.markAsDirty({ onlySelf: true });
  control.updateValueAndValidity({ onlySelf: true });
}
