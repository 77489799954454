import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SegmentsState } from '../store/segments.state';
import { SegmentsSelectors } from '../store/segments.selectors';
import { SegmentsActions } from '../store/segments.actions';
import { Segment } from './segments.models';
import { Observable } from 'rxjs';
import { GetProfilesResponse } from '../../contacts/shared/contacts.models';
import { SegmentsRepository } from './segments.repository';

@Injectable({ providedIn: 'root' })
export class SegmentsFacade {
  segments$ = this.store.pipe(select(SegmentsSelectors.selectSegments));
  selectedSegment$ = this.store.pipe(select(SegmentsSelectors.selectSelectedSegment));
  segmentContacts$ = this.store.pipe(select(SegmentsSelectors.selectSegmentContacts));

  constructor(
    private store: Store<SegmentsState>,
    private segmentsRepository: SegmentsRepository,
  ) {}

  setSegments(): void {
    this.store.dispatch(new SegmentsActions.SetSegments());
  }

  setSelectedSegment(segment: Segment): void {
    this.store.dispatch(new SegmentsActions.SetSelectedSegment(segment));
  }

  getSegmentProfiles(id: string): Observable<GetProfilesResponse> {
    return this.segmentsRepository.getSegmentProfiles(id);
  }
}
